import React, {useEffect, useState} from "react";
import "./App.css";

const App = () => {

  const [countdown, setCountdown] = useState(15); // Initial countdown value

  useEffect(() => {
    // Countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect when countdown reaches 0
    if (countdown === 0) {
      proceedToNewKasukiURL()
    }

    // Cleanup the timer
    return () => clearInterval(timer);
  }, [countdown]);

  const proceedToNewKasukiURL = () => {
    window.location.href = 'https://portal.dev.mysuki.io';
  }

  return (
    <div className="container">
      <div className="text-center">
        <div>
          This page will redirect you to{' '}
          <strong className="highlight">portal.dev.mysuki.io</strong> in{' '}
          <strong>{countdown}</strong>
        </div>
        <div><button className="proceed-btn" onClick={()=>proceedToNewKasukiURL()}>Proceed</button></div>
      </div>
    </div>
  );
};

export default App;
